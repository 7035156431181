import React, { useEffect, useState } from "react";
import * as THREE from "three";
import random from "random";
import createInputEvents from "simple-input-events";

let config = {
  long: 200,
  radius: [30, 40],
  speed: [0.05, 0.1],
  radiusSeparation: 0,
  rotationFrequency: 8,
  // instances per thingy
  nInstances: 1000,
  useCube: false
};

export const Instancing = ({ className, children }) => {
  let containerRef = React.createRef();
  let canvasRef = React.createRef();
  let webglRef = React.createRef(null);
  let [webglInstance, setWebgl] = React.useState(() => null);

  useEffect(() => {
    if (webglInstance == null) {
      let app = new webgl(containerRef.current, canvasRef.current, config);
      // webglRef.current = app;
      app.init();
      setWebgl(app);
    }

    return () => {
      if (webglInstance != null) {
        if (!webglInstance.disposed) webglInstance.dispose();
        setWebgl(null); // this fires this function again
      }
    };
  }, [containerRef.current, canvasRef.current, webglInstance]);

  // useEffect(() => {
  //   if (webglInstance == null) return;

  //   // webglInstance.onMove(position);
  // }, [position]);

  // console.log("rendering");

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen overflow-hidden z-minus1"
      ref={containerRef}
    >
      <canvas className="w-full h-full" ref={canvasRef} />
    </div>
  );
};

export default Instancing;
let fragmentShader = `
#define PI 3.14159265359
varying vec2 vUv;
varying vec3 vColor;
varying vec3 vEndColor;
varying float vProgress;
vec3 palette( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d )
{
    return a + b*cos( 6.28318*(c*t+d) );
}

${THREE.ShaderChunk["common"]}
${THREE.ShaderChunk["fog_pars_fragment"]}
  void main(){
    vec3 color = vColor;
    color = palette(cos(vProgress * PI*2.)*0.5+0.5, vColor, vColor*0.4, vec3(2.), vec3(2.));
    gl_FragColor = vec4(color, 1.);
    ${THREE.ShaderChunk["fog_fragment"]}
  }
`;

let vertexShader = `
#define PI 3.14159265359
attribute vec4 aCurve;
attribute vec3 aOffset;
varying vec2 vUv;
uniform float uRadius;
uniform float uTime;
uniform vec2 uMouse;
uniform float uScale;

attribute vec3 aColor;
attribute vec4 aEndPosition;
varying vec3 vEndColor;
varying vec3 vColor;
varying float vProgress;

${THREE.ShaderChunk["common"]}
${THREE.ShaderChunk["fog_pars_vertex"]}
  vec2 getScreenNDC(vec3 pos){
    // https://stackoverflow.com/questions/26965787/how-to-get-accurate-fragment-screen-position-like-gl-fragcood-in-vertex-shader
    vec4 clipSpace = projectionMatrix* modelViewMatrix * vec4(pos, 1.);
    vec3 ndc = clipSpace.xyz / clipSpace.w; //perspective divide/normalize
    vec2 viewPortCoord = ndc.xy; //ndc is -1 to 1 in GL. scale for 0 to 1
    return viewPortCoord;
  }
  mat2 rotate2d(float _angle){
    return mat2(cos(_angle),-sin(_angle),
                sin(_angle),cos(_angle));
}
vec3 getCurve(float progress){
  vec3 pos = vec3(0.);
  pos.x += cos(progress * PI * 2.) * uRadius;
  pos.z += sin(progress * PI * 2. ) * 40. - 10.;
  pos.y += sin(progress * PI * 2.) * uRadius ;
  return pos;
}
  void main(){
    vec3 transformed = position.xyz;
    float speed = aCurve.w;
    float progress = mod(aCurve.x + uTime * speed , 1.);


    vec3 spherePosition = getCurve(progress);
    vec2 SphereViewportCoord =getScreenNDC(spherePosition+ aOffset); //ndc is -1 to 1 in GL. scale for 0 to 1

    float dist = length((uMouse) - SphereViewportCoord);
    
    if(dist < 0.4){
      transformed *= 1.+smoothstep(0.5,1., (1.-dist/0.4)) *2.5;
    }
    float scale = smoothstep(0., (sin(progress * PI * 2.) * 0.5+0.5) *0.7 + 0.299, uScale);
    transformed *= aCurve.z * scale;
    
    transformed += aOffset;
    transformed += spherePosition;
    vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.);
    gl_Position = projectionMatrix* mvPosition;

    vUv = uv;
    vColor = vec3(aColor);
    vProgress = progress;
    ${THREE.ShaderChunk["fog_vertex"]}
  }
`;

let baseGeometry = new THREE.SphereBufferGeometry(1, 16, 16);
let baseCube = new THREE.BoxBufferGeometry(1, 1, 1);
export class Spheres extends THREE.Mesh {
  constructor(config, colors, uniforms) {
    super();
    this.config = config;
    this.colors = colors;
    this.uniforms = {
      ...THREE.UniformsLib["fog"],
      uRadius: new THREE.Uniform(config.radius),
      uScale: new THREE.Uniform(0),
      ...uniforms
    };
    let material = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader,
      uniforms: this.uniforms,
      fog: true
    });
    this.material = material;
  }
  init() {
    let config = this.config;
    let instancedGeometry = new THREE.InstancedBufferGeometry().copy(
      this.config.useCube ? baseCube : baseGeometry
    );
    let instanceCount = this.config.nInstances;
    instancedGeometry.maxInstancedCount = instanceCount;
    let aNot = [];
    let aColor = [];
    let aEndColor = [];
    let endSize = 20;
    let types = [
      {
        position: new THREE.Vector3(0, endSize / 2, 0),
        color: this.colors[0]
      },
      {
        color: this.colors[1],
        position: new THREE.Vector3(0, -endSize / 2, -endSize)
      }
    ];
    let aOffset = [];
    let offsetSize = 3;

    let variableWidth = this.config.radius[0] - this.config.radius[1];
    let offsetVector = new THREE.Vector3();

    let aCurve = [];
    for (let i = 0; i < instanceCount; i++) {
      let x = random.float(-1, 1);
      let y = random.float(-1, 1);
      let z = random.float(-1, 1);

      let mag = Math.sqrt(x * x + y * y + z * z);
      x /= mag;
      y /= mag;
      z /= mag;

      let d = random.float(0, this.config.offsetRadius);

      x *= d;
      y *= d;
      z *= d;

      let color = this.colors[random.int(0, this.colors.length - 1)];
      aColor.push(color.r, color.g, color.b);
      let direction = this.config.direction;

      aOffset.push(x, y, z);

      let progress = random.float();
      let scale = random.float(0.2, 0.4);
      let speed = random.float(config.speed[0], config.speed[1]);

      aCurve.push(progress, direction, scale, speed);
    }

    // forloop

    instancedGeometry.setAttribute(
      "aCurve",
      new THREE.InstancedBufferAttribute(new Float32Array(aCurve), 4, false)
    );
    instancedGeometry.setAttribute(
      "aColor",
      new THREE.InstancedBufferAttribute(new Float32Array(aColor), 3, false)
    );
    instancedGeometry.setAttribute(
      "aOffset",
      new THREE.InstancedBufferAttribute(new Float32Array(aOffset), 3, false)
    );

    this.geometry = instancedGeometry;
    this.frustumCulled = false;
  }
  clean() {
    this.geometry.dispose();
  }
  update(time) {
    this.uniforms.uTime.value = time;
  }
  updatePointer(pointer) {
    this.uniforms.uMouse.value.set(pointer.x, pointer.y);
  }
  updateScale(scale) {
    this.uniforms.uScale.value = scale;
  }
  dispose() {
    // this.geometry.dispose();
    // baseGeometry.dispose();
    this.material.dispose();
  }
}

class BasicThreeDemo {
  constructor(container, canvas) {
    this.container = container;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      stencil: false,
      alpha: true,
      canvas: canvas
    });
    this.renderer.setSize(container.offsetWidth, container.offsetHeight, false);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    // container.append(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      45,
      container.offsetWidth / container.offsetHeight,
      0.1,
      10000
    );
    this.camera.position.z = 50;
    this.scene = new THREE.Scene();

    this.clock = new THREE.Clock();
    this.assets = {};
    this.disposed = false;
    this.tick = this.tick.bind(this);
    this.init = this.init.bind(this);
    this.setSize = this.setSize.bind(this);
  }
  loadAssets() {
    return new Promise((resolve, reject) => {
      // const manager = new THREE.LoadingManager(resolve);
      // this.text.load(manager);
    });
  }
  init() {
    this.tick();
  }
  getViewSizeAtDepth(depth = 0) {
    const fovInRadians = (this.camera.fov * Math.PI) / 180;
    const height = Math.abs((this.camera.position.z - depth) * Math.tan(fovInRadians / 2) * 2);
    return { width: height * this.camera.aspect, height };
  }
  setSize(width, height, updateStyle) {
    this.renderer.setSize(width, height, false);
  }
  onResize() {}
  dispose() {
    this.disposed = true;
  }
  update() {}
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  tick() {
    if (this.disposed) return;
    if (resizeRendererToDisplaySize(this.renderer, this.setSize)) {
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
      this.onResize();
    }
    let didUpdate = this.update();
    if (didUpdate) this.render();
    requestAnimationFrame(this.tick);
  }
}

function resizeRendererToDisplaySize(renderer, setSize) {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    setSize(width, height, false);
  }
  return needResize;
}
class webgl extends BasicThreeDemo {
  constructor(container, canvas, config) {
    super(container, canvas);
    this.config = config;
    this.camera.position.z = 50;
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.text = new Text(this);

    this.uniforms = {
      uTime: new THREE.Uniform(0),
      uMouse: new THREE.Uniform(new THREE.Vector2(-1, -1))
    };

    this.scene.fog = new THREE.Fog("#0F121D", 0.1, 120);

    this.targetScale = 1;
    this.scale = 0;

    this.rings = [];
    // for (let i = 0; i < 8; i++) {
    //   this.rings.push(new Spheres(config, colors[i % 2], this.uniforms));
    // }

    this.topSpheres = new Spheres(
      { ...config, radius: 5, offsetRadius: 1.5, direction: 1 },
      [new THREE.Color("#ff3030"), new THREE.Color("#201213"), new THREE.Color("#dd5060")],
      this.uniforms
    );
    this.secondSpheres = new Spheres(
      { ...config, radius: 5, offsetRadius: 1.5, direction: -1 },
      [new THREE.Color("#5050ff"), new THREE.Color("#121220"), new THREE.Color("#6060aa")],
      this.uniforms
    );

    this.rings.push(this.topSpheres, this.secondSpheres);
    // this.scene.background = new THREE.Color("#1c1d1e");

    this.onMove = this.onMove.bind(this);
    this.onTap = this.onTap.bind(this);
    this.restart = this.restart.bind(this);

    this.event = createInputEvents(this.container);
  }
  loadAssets() {
    return new Promise((resolve, reject) => {
      const manager = new THREE.LoadingManager(resolve);
      manager.itemStart("a");
      manager.itemEnd("a");
    });
  }
  restart() {
    this.rings.forEach(ring => {
      ring.clean();
      ring.init();
    });
  }
  onMove({ event }) {
    let view = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1
    };

    this.uniforms.uMouse.value.set(view.x, view.y);
  }
  dispose() {
    this.disposed = true;
    this.event.disable();
    this.scene.dispose();
  }
  onTap() {
    return;
  }
  init() {
    this.topSpheres.init();
    this.secondSpheres.init();

    this.scene.add(this.topSpheres);
    this.scene.add(this.secondSpheres);

    this.onResize();

    this.event.on("move", this.onMove);
    // this.event.on("down", this.onTap);

    this.tick();
  }
  onResize() {
    let viewSize = this.getViewSizeAtDepth();

    let positionTop = new THREE.Vector3(0, 0, 0);
    let rotationTop = new THREE.Euler(0, 0, 0, "XYZ");
    let scaleTop = new THREE.Vector3(1, 1, 1);

    let positionBottom = new THREE.Vector3(0, 0, 0);
    let rotationBottom = new THREE.Euler(0, 0, 0, "XYZ");
    let scaleBottom = new THREE.Vector3(1, 1, 1);
    let scale = 1;

    let w = Math.min(1, window.innerWidth / 1280);

    let mix = (a, b, t) => a * (1 - t) + b * t;

    if (window.innerWidth < 600 || true) {
      //   positionTop.x = -(viewSize.width / 2) + mix(-8, 0, w);
      //   positionTop.y = viewSize.height / 2 + 7;
      //   positionTop.z = mix(-20, -3, w);
      //   rotationTop.y = mix(0.2, 0.5, w);

      positionBottom.x = viewSize.width / 2 + mix(7, 0, w);
      positionBottom.y = viewSize.height / 2 - 7;
      rotationBottom.y = mix(0, 0.4, w);
      // rotationBottom.y = 0.19;
      rotationBottom.x = Math.PI * 1.13;
      positionBottom.z = mix(-20, -3, w);

      positionTop.x = -viewSize.width / 2 - mix(7, 0, w);
      positionTop.y = -viewSize.height / 2 + 7;
      rotationTop.y = -mix(0, 0.4, w);
      // rotationTop.y = 0.19;
      rotationTop.x = Math.PI * 1.13 - 0.6;
      positionTop.z = mix(-20, -3, w);
    }
    if (window.innerWidth > 1000) {
      let wBig = Math.max(0, window.innerWidth - 1000) * 0.0005;

      rotationTop.x = Math.PI * 1.13 - wBig * 0.2 - 0.6;
      rotationTop.y = -mix(0, 0.4, w) - wBig * 0.3;
      positionTop.x += -wBig * 8;

      rotationBottom.x = Math.PI * 1.13 + wBig * 0.1;
      rotationBottom.y = mix(0, 0.4, w) + wBig * 0.1;
      positionBottom.x -= wBig * 8;

      scale = 1 + wBig * 0.75;
    }

    scaleTop.set(scale, scale, scale);
    scaleBottom.set(scale, scale, scale);

    this.topSpheres.position.copy(positionTop);
    this.topSpheres.rotation.copy(rotationTop);
    this.topSpheres.scale.copy(scaleTop);

    this.secondSpheres.position.copy(positionBottom);
    this.secondSpheres.rotation.copy(rotationBottom);
    this.secondSpheres.scale.copy(scaleBottom);
  }
  updateScale() {
    this.topSpheres.updateScale(this.scale);
    this.secondSpheres.updateScale(this.scale);
  }
  update() {
    let time = this.clock.getElapsedTime();
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    let yPercent = top / window.innerHeight;
    this.targetScale = 1 - Math.min(1, yPercent);

    let change = (this.targetScale - this.scale) * (0.005 + yPercent * 0.01);
    this.scale += change;

    if (change) this.updateScale();

    let didChange = !(change === 0 && this.targetScale === 0);

    if (didChange) {
      this.uniforms.uTime.value = time;
    }

    return didChange;
  }
}
