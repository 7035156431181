import React, { useState } from "react";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

export const Newsletter = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [isSuccess, setSuccess] = useState(null);
  const onSubmit = data => {
    addToMailchimp(data.email, { FNAME: data.name })
      .then(data => {
        if (data.result === "success") {
          setSuccess(data.msg);
        }
      })
      .catch(() => {
        console.log("Mailchimp never throws errors. Panic");
      });
  };

  return (
    <div className="relative w-full px-4 sm:px-0 mb-9 pt-6 md:pt-9 border-t-1 border-white">
      {isSuccess != null && (
        <div className="absolute text-center top-0 bg-darkBlue px-4 left-0 right-0 bottom-0 flex items-center justify-center">
          <div className=" max-w-2xl mx-auto">
            Thanks for subscribing! Please check your inbox and confirm your subscription!
            <br /> I just need to know you are real.
          </div>
        </div>
      )}
      <div className="flex justify-center flex-col md:flex-row">
        <div className="w-full mx-auto md:mx-0 sm:w-10/12 md:w-7/12 lg:w-5/12 pb-4 md:pb-0 sm:pr-1">
          <h3 className="font-display text-2lg xs:text-xl mb-3 sm:text-2xl lg:text-3xl font-regular leading-tight tracking-normal">
            Me make article or project.
            <br />
            You get email or read it?
          </h3>
          <p className="text-sm leading-relaxed tracking-wide text-lowWhite">
            A newsletter about learning Web-creative! <br />
            I'll share anything interesting I read or write. WebGl, canvas, creative-coding etc
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full mx-auto sm:w-10/12 md:mx-0 md:w-4/12 lg:w-3/12 md:pl-1 flex flex-col items-end md:-mt-5"
        >
          <label htmlFor="name-input" className="w-full mb-1 pl-1 font-display">
            Name
          </label>
          <input
            type="text"
            id="name-input"
            className="mb-4 py-2 px-4 w-full border-1 border-white bg-transparent rounded-soft font-display"
            name="name"
            placeholder="Jose"
            ref={register}
          />
          <p htmlFor="email-input" className="w-full mb-1 pl-1 font-display">
            Email
          </p>
          <input
            type="text"
            id="email-input"
            className="mb-4 py-2 px-4 w-full border-1 border-white bg-transparent rounded-soft font-display"
            name="email"
            placeholder="jose@gmail.com"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address"
              }
            })}
          />
          <input
            type="submit"
            className="e-bump cursor-pointer font-display w-full sm:w-auto rounded-soft flex-grow-0 border-1 border-white hover:bg-transparent hover:text-white py-2 px-6 bg-white text-black "
            value="Subscribe"
          />
        </form>
      </div>
    </div>
  );
};
