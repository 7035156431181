import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { CodeBlock } from "./CodeBlock";
import { buildImageObj, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import Container from "./container";
import AuthorList from "./author-list";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { Instancing } from "./articles/instancing.js";
import ArticleComponents from "./articles";
import clientConfig from "../../client-config";
import Img from "gatsby-image/withIEPolyfill";
import { Newsletter } from "./Newsletter";

import styles from "./blog-post.module.css";

const exampleCode = `
function someDemo() {
  var test = "Hello World!";
  console.log(test);
};
`;

function BlogPost(props) {
  const {
    _rawBody,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
    customComponentName
  } = props;
  const fluidProps = getFluidGatsbyImage(
    mainImage.asset._id,
    { maxWidth: 3000 },
    clientConfig.sanity
  );
  // The main image always has the same ratio
  fluidProps.aspectRatio = 16 / 9;

  let CustomComponent = null;

  if (customComponentName && ArticleComponents[customComponentName]) {
    CustomComponent = ArticleComponents[customComponentName];
  }

  return (
    <article>
      <header className="pb-5">
        {CustomComponent && <CustomComponent />}
        <h5 className="w-full mx-auto text-center text-normal font-display pb-1">
          {differenceInDays(new Date(publishedAt), new Date()) > 3
            ? distanceInWords(new Date(publishedAt), new Date())
            : format(new Date(publishedAt), "MMMM Do YYYY")}
        </h5>
        <h1 className="w-full font-light sm:w-12/12 md:w-11/12 xl:w-9/12 mx-auto text-center text-2xl sm:text-3xl md:text-3xl lg:text-4xl font-display leading-snug">
          {title}
        </h1>
      </header>
      {!props.disableMainImageInArticle && (
        <ImageWrapper>
          {mainImage && mainImage.asset && (
            <Img
              fluid={fluidProps}
              alt={mainImage.alt}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          )}
        </ImageWrapper>
      )}
      {_rawBody && <PortableText blocks={_rawBody} />}
      <div className="mt-5">
        <Newsletter />
      </div>
    </article>
  );
}
let ImageWrapper = ({ className, ...props }) => {
  return (
    <div
      className={cn(
        `-ml-5 sm:-ml-3  w-full-bleed-2 sm:w-full-bleed md:w-11/12 lg:w-8/12-bleed  xl:w-9/12-bleed md:mx-auto mb-7`,
        className
      )}
      {...props}
    />
  );
};

export default BlogPost;
